/**
 * Fetcher Security
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FSecurity extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/security/', '/Home/Security/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取安全检查基本设置（安检设置）
    getSecurityInfo() {
        const url = this.spellURL(
            'getSecurityInfo',
            '../../lpg/admin/securityManagement/getSecurityInfo',
        );
        const send = {};
        return this.post(url, send);
    }

    // 保存安检配置修改（安检设置）
    saveSecurityInfoChange(info) {
        const url = this.spellURL(
            'saveSecurityInfoChange',
            '../../lpg/admin/securityManagement/setSecurityInfo',
        );
        const send = {
            ...this.transKeyName('underline', info),
            dispatch_freq: '',
        };
        return this.post(url, send);
    }

    // 获取安检项目列表（安检设置）
    getSecurityItems(search, pagination, sorter) {
        const url = this.spellURL(
            'getSecurityItems',
            '../../lpg/admin/securityManagement/getSecurityItemList',
        );
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取安检项目（筛选项）
    getSecurityItemsAsSelect() {
        const url = this.spellURL(
            'getSecurityItemsFilter',
            '../../lpg/admin/securityManagement/getSecurityItemList',
        );
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            const rows2 = (res.rows || [])
                .filter(item => item.online === 1)
                .sort((a, b) => (a.typeId > b.typeId && a.level > b.level ? -1 : 1));
            return rows2.map(item => ({
                id: item.id,
                name: item.item,
            }));
        });
    }

    // 保存安检项目是否启用（安检设置）
    saveSecurityItemOnline(online) {
        const url = this.spellURL(
            'saveSecurityItemOnline',
            '../../lpg/admin/securityManagement/switchSecurityItem',
        );
        const send = {
            ...online,
            op: 'switch',
        };
        return this.post(url, send);
    }

    // 保存安检项目（安检设置）
    saveSecurityItem(item) {
        const url = this.spellURL(
            'saveSecurityItem',
            '../../lpg/admin/securityManagement/setSecurityItem',
        );
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(item)),
        };
        return this.post(url, send);
    }

    // 获取安检类型列表（安检设置）
    getSecurityTypes(search, pagination, sorter) {
        const url = this.spellURL(
            'getSecurityTypes',
            '../../lpg/admin/securityManagement/securityTypeList',
        );
        const send = {
            ...search,
            page_num: pagination.current,
            page_size: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取安检类型（筛选项）
    getSecurityTypesAsSelect() {
        const url = this.spellURL(
            'getSecurityTypesFilter',
            '../../lpg/admin/securityManagement/securityTypeList',
        );
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            const rows = res.rows;
            rows.sort((a, b) => (a.level > b.level ? -1 : 1));
            return rows.map(item => ({
                id: item.typeId,
                name: item.type,
                disabled: item.status !== 1,
            }));
        });
    }

    // 保存安检类型状态（安检设置）
    saveSecurityTypeStatus(status) {
        const url = this.spellURL(
            'saveSecurityTypeStatus',
            '../../lpg/admin/securityManagement/switchSecurityType',
        );
        const send = {
            ...status,
        };
        return this.post(url, send);
    }

    // 保存安检类型（安检设置）
    saveSecurityType(item) {
        const url = this.spellURL(
            'saveSecurityType',
            '../../lpg/admin/securityManagement/setSecurityType',
        );
        const send = {
            ...this.transKeyName('underline', item),
        };
        return this.post(url, send);
    }

    // 获取安检记录（安检记录）
    getSecurityCheckList(search, pagination, sorter) {
        const url = this.spellURL(
            'getSecurityCheckList',
            '../../lpg/admin/securityManagement/getSecurityCheckList',
        );
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            time: search.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取安检记录-导出地址（安检记录）
    getSecurityCheckExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_security_check');
        const send = {
            ...search,
            tel: search.phone,
            time: search.time?.replace(',', ' - '),
        };
        delete send.phone;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取安检自查记录（安检自查）
    getSecuritySelfChecks(search, pagination, sorter) {
        const url = this.spellURL('getSecuritySelfChecks', 'security_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            time: search.time?.replace(',', ' - '),
        };
        return this.post(url, send, [['rows.[]', 'userid', 'userId']]);
    }

    // 获取安检各项目计数（安检项目统计）
    getSecurityItemCounts(search, pagination, sorter) {
        const url = this.spellURL(
            'getSecurityItemCounts',
            '../../lpg/admin/securityManagement/getSecurityCheckStatistic',
        );
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取安检各项目计数（安检项目统计新）
    getSecurityItemCountsV2(search) {
        const url = this.spellURL(
            'getSecurityItemCountsV2.' + search.securityType,
            '../../lpg/admin/securityManagement/getSecurityOrderStatistic',
        );
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取安检配置树目录数据列表（安检配置新）
    getSecurityTreeList(security) {
        const url = this.spellURL(
            'getSecurityTreeList',
            '../../lpg/admin/securityManagement/getSecurityRectifyList',
        );
        const send = {
            ...this.transKeyName('underline', security),
        };
        return this.post(url, send);
    }

    // 获取安检配置树单项信息（安检配置新）
    getSecurityTreeItem(item) {
        const url = this.spellURL(
            'getSecurityTreeItem',
            '../../lpg/admin/securityManagement/getSecurityRectifyInfo',
        );
        const send = {
            ...item,
        };
        return this.post(url, send);
    }

    // 保存安检配置树单项信息（安检配置新）
    saveSecurityTreeItem(item) {
        const url = this.spellURL(
            'saveSecurityTreeItem',
            '../../lpg/admin/securityManagement/setSecurityRectify',
        );
        const { parentId, ...restItem } = this.trimEndsSpace(item);
        const send = {
            ...this.transKeyName('underline', restItem),
            parent_id: parentId,
        };
        return this.post(url, send);
    }

    // 获取安检配置整改项列表（安检配置新）
    getSecurityRectifyList(item) {
        const url = this.spellURL(
            'getSecurityRectifyList',
            '../../lpg/admin/securityManagement/getSecurityRectifyCorrect',
        );
        const send = {
            ...item,
        };
        return this.post(url, send);
    }

    // 保存安检项绑定整改项（安检配置新）
    saveSecurityBindRectify(item) {
        const url = this.spellURL(
            'saveSecurityBindRectify',
            '../../lpg/admin/securityManagement/bindSecurityLink',
        );
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(item)),
        };
        return this.post(url, send);
    }

    // 获取安检配置分享码（安检配置新）
    getSecurityShareCode(security) {
        const url = this.spellURL(
            'getSecurityShareCode',
            '../../lpg/admin/securityManagement/getSecurityItemShareCode',
        );
        const send = {
            ...this.transKeyName('underline', security),
        };
        return this.post(url, send);
    }

    // 保存安检配置分享码（安检配置新）
    saveSecurityShareCode(code) {
        const url = this.spellURL(
            'saveSecurityShareCode',
            '../../lpg/admin/securityManagement/shareCodeSecurityItemCopy',
        );
        const send = {
            ...this.transKeyName('underline', code),
        };
        return this.post(url, send);
    }

    // 获取安检记录列表（安检记录新）
    getSecurityCheckRecords(search, pagination, sorter) {
        const url = this.spellURL(
            'getSecurityCheckRecords',
            '../../lpg/admin/securityManagement/getSecurityOrderList',
        );
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 异步导出安检记录（安检记录随瓶,定期）
    saveExportSecurityRecord(search) {
        const url = this.spellURL('saveExportSecurityRecord', 'exportSecurityList');
        const send = {
            ...this.transKeyName('underline', search),
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取异步导出安检记录结果（安检记录随瓶,定期）
    getExportSecurityRecordResult(data) {
        const url = this.spellURL(
            'getExportSecurityRecordResult',
            '../Gangping/getExportArchiveResult',
        );
        const send = {
            ...this.transKeyName('underline', data),
        };
        return this.post(url, send);
    }

    // 安检结果审核
    saveSecurityVerify(verify) {
        const url = this.spellURL(
            'saveSecurityVerify',
            '../../lpg/admin/securityManagement/checkSecurityOrder',
        );
        const send = {
            ...this.transKeyName('underline', verify),
        };
        return this.post(url, send);
    }

    // 获取安检单数据（安检记录新）
    getSecurityCheckPrint(check) {
        const url = this.spellURL(
            'getSecurityCheckPrint.1',
            '../../lpg/admin/securityManagement/getSecurityOrder',
        );
        const send = {
            ...check,
        };
        return this.post(url, send);
    }

    // 获取安检记录设置（安检记录新）
    getSecurityConfig() {
        const url = this.spellURL('getSecurityConfig', 'setSecurityinfo');
        const send = {
            is_edit: 0,
        };
        return this.post(url, send);
    }

    // 保存安检记录设置（安检记录新）
    saveSecurityConfig(config) {
        const url = this.spellURL('saveSecurityConfig', 'setSecurityinfo');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(config)),
            is_edit: 1,
        };
        return this.post(url, send);
    }

    // 保存添加新整改单
    saveAddNewRectify(rectify) {
        const url = this.spellURL(
            'saveAddNewRectify',
            '../../lpg/admin/securityManagement/addCorrectOrder',
        );
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(rectify)),
        };
        return this.post(url, send);
    }

    // 获取整改单列表（安检整改单）
    getRectifyList(search, pagination, sorter) {
        const url = this.spellURL(
            'getRectifyList',
            '../../lpg/admin/securityManagement/getCorrectList',
        );
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取整改项列表（安检整改单）
    getCorrectRectify() {
        const url = this.spellURL('getCorrectRectify', 'correct_rectify');
        return this.post(url);
    }

    // 获取整改单详情（安检整改单）
    getRectifyDetail(rectify) {
        const url = this.spellURL(
            'getRectifyDetail',
            '../../lpg/admin/securityManagement/getCorrectDetail',
        );
        const send = {
            ...this.transKeyName('underline', rectify),
        };
        return this.post(url, send);
    }

    // 保存整改单指派（安检整改单）
    saveRectifyAppoint(appoint) {
        const url = this.spellURL(
            'saveRectifyAppoint',
            '../../lpg/admin/securityManagement/appointCorrect',
        );
        const send = {
            ...this.transKeyName('underline', appoint),
        };
        return this.post(url, send);
    }

    // 保存整改单取消（安检整改单）
    saveRectifyCancel(cancel) {
        const url = this.spellURL(
            'saveRectifyCancel',
            '../../lpg/admin/securityManagement/cancelCorrectOrder',
        );
        const send = {
            ...this.transKeyName('underline', cancel),
        };
        return this.post(url, send);
    }

    // 读取报警记录列表（报警记录）
    getAlarmRecords(search, pagination, sorter) {
        const url = this.spellURL('getAlarmRecords', 'alert_record_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取报警器列表（报警器列表）
    getAlarmList(search, pagination, sorter) {
        const url = this.spellURL('getAlarmList', 'alert_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }
}

export const $fetchSecurity = new FSecurity();
